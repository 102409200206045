import Vue from "vue";
import VueRouter from "vue-router";

import { canNavigate } from "@/libs/acl/routeProtection";
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from "@/auth/utils";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/profile",
      name: "User Profile",
      component: () => import("@/views/Profile/Index.vue"),
      meta: {
        pageTitle: "User Profile",
        pageIcon: "UserIcon",
        breadcrumb: [
          {
            text: "User Profile",
            active: true,
          },
        ],
      },
    },
    {
      path: "/",
      name: "dashboard",
      component: () => import("@/views/Dashboard/index.vue"),
      meta: {
        pageTitle: "Dashboard",
        pageIcon: "HomeIcon",
        breadcrumb: [
          {
            text: "Dashboard",
            active: true,
          },
        ],
      },
    },
    {
      path: "/mention",
      name: "mention",
      component: () => import("@/views/Mention/index.vue"),
      meta: {
        pageTitle: "Mention",
        pageIcon: "ListIcon",
        breadcrumb: [
          {
            text: "Berita & Postingan",
            active: true,
          },
        ],
      },
    },
    {
      path: "/source",
      name: "source",
      component: () => import("@/views/Source/index.vue"),
      meta: {
        pageTitle: "Source",
        pageIcon: "UsersIcon",
        breadcrumb: [
          {
            text: "Source",
            active: true,
          },
        ],
      },
    },
    {
      path: "/news-trend",
      name: "newstrend",
      component: () => import("@/views/NewsTrend/index.vue"),
      meta: {
        pageTitle: "News Trend",
        pageIcon: "TrendingUpIcon",
        breadcrumb: [
          {
            text: "News Trend",
            active: true,
          },
        ],
      },
    },
    // DETAIL NEWS TREND
    {
      path: "/detail-news-trend",
      name: "detail-newstrend",
      component: () => import("@/components/newstrend/detailNewsTrend.vue"),
      meta: {
        pageTitle: "News Trend",
        pageIcon: "TrendingUpIcon",
        breadcrumb: [
          {
            text: "Detail News Trend",
            active: true,
          },
        ],
      },
    },
    {
      path: "/data-cleansing",
      name: "datacleansing",
      component: () => import("@/views/DataCleansing/index.vue"),
      meta: {
        pageTitle: "Data Cleansing",
        pageIcon: "DeleteIcon",
        breadcrumb: [
          {
            text: "Data Cleansing",
            active: true,
          },
        ],
      },
    },
    {
      path: "/analytic/general",
      name: "analytic-general",
      component: () => import("@/views/Analytic/General/index.vue"),
      meta: {
        pageTitle: "General",
        pageIcon: "LayersIcon",
        breadcrumb: [
          {
            text: "Analytic",
            active: true,
          },
          {
            text: "General",
            active: true,
          },
        ],
      },
    },
    {
      path: "/analytic/maps",
      name: "analytic-maps",
      component: () => import("@/views/Analytic/Maps/index.vue"),
      meta: {
        pageTitle: "Maps",
        pageIcon: "MapIcon",
        breadcrumb: [
          {
            text: "Analytic",
            active: true,
          },
          {
            text: "Maps",
            active: true,
          },
        ],
      },
    },
    {
      path: "/analytic/sna",
      name: "analytic-sna",
      component: () => import("@/views/Analytic/SNA/index.vue"),
      meta: {
        pageTitle: "SNA",
        pageIcon: "Share2Icon",
        breadcrumb: [
          {
            text: "Analytic",
            active: true,
          },
          {
            text: "Sosial Network Analytic",
            active: true,
          },
        ],
      },
    },
    {
      path: "/analytic/ner",
      name: "analytic-ner",
      component: () => import("@/views/Analytic/NER/index.vue"),
      meta: {
        pageTitle: "NER",
        pageIcon: "GitPullRequestIcon",
        breadcrumb: [
          {
            text: "Analytic",
            active: true,
          },
          {
            text: "Named Entity Recognizer",
            active: true,
          },
        ],
      },
    },
    {
      path: "/analytic/nea",
      name: "analytic-nea",
      component: () => import("@/views/Analytic/NEA/index.vue"),
      meta: {
        pageTitle: "NEA",
        pageIcon: "GitMergeIcon",
        breadcrumb: [
          {
            text: "Analytic",
            active: true,
          },
          {
            text: "Named Entity Analysis",
            active: true,
          },
        ],
      },
    },
    {
      path: "/analytic/narasumber",
      name: "analytic-narasumber",
      component: () => import("@/views/Analytic/Narasumber/index.vue"),
      meta: {
        pageTitle: "Narasumber",
        pageIcon: "UserIcon",
        breadcrumb: [
          {
            text: "Analytic",
            active: true,
          },
          {
            text: "Narasumber",
            active: true,
          },
        ],
      },
    },
    {
      path: "/trash-bin",
      name: "trash-bin",
      component: () => import("@/views/TrashBin/index.vue"),
      meta: {
        pageTitle: "Trash Bin",
        pageIcon: "Trash2Icon",
        breadcrumb: [
          {
            text: "Trash Bin",
            active: true,
          },
        ],
      },
    },
    {
      path: "/setting/keyword",
      name: "setting-keyword",
      component: () => import("@/views/Setting/Keyword/index.vue"),
      meta: {
        pageTitle: "Keyword",
        pageIcon: "SettingsIcon",
        breadcrumb: [
          {
            text: "Setting",
            active: true,
          },
          {
            text: "Keyword",
            active: true,
          },
        ],
      },
    },
    {
      path: "/setting/user",
      name: "setting-user",
      component: () => import("@/views/Setting/User/index.vue"),
      meta: {
        pageTitle: "User",
        pageIcon: "SettingsIcon",
        breadcrumb: [
          {
            text: "Setting",
            active: true,
          },
          {
            text: "User",
            active: true,
          },
        ],
      },
    },
    {
      path: "/setting/channel",
      name: "setting-channel",
      component: () => import("@/views/Setting/Channel/index.vue"),
      meta: {
        pageTitle: "Channel",
        pageIcon: "SettingsIcon",
        breadcrumb: [
          {
            text: "Setting",
            active: true,
          },
          {
            text: "Channel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/setting/media",
      name: "setting-media",
      component: () => import("@/views/Setting/Media/Index.vue"),
      meta: {
        pageTitle: "Media",
        pageIcon: "SettingsIcon",
        breadcrumb: [
          {
            text: "Setting",
            active: true,
          },
          {
            text: "Media",
            active: true,
          },
        ],
      },
    },
    {
      path: "/setting/notification-email",
      name: "setting-notifemail",
      component: () => import("@/views/Setting/NotificationAndEmail/index.vue"),
      meta: {
        pageTitle: "Notification & Email",
        pageIcon: "SettingsIcon",
        breadcrumb: [
          {
            text: "Setting",
            active: true,
          },
          {
            text: "Notification & Email",
            active: true,
          },
        ],
      },
    },
    {
      path: "/detail-akun",
      name: "detail-akun",
      component: () => import("@/components/usable/detailAccount.vue"),
      meta: {
        pageTitle: "Detail Akun",
        pageIcon: "UserIcon",
        breadcrumb: [
          {
            text: "Detail Akun",
            active: true,
          },
        ],
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login/index.vue"),
      meta: {
        layout: "full",
        resource: "Auth",
        redirectIfLoggedIn: true,
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/Error404/Index.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.name !== "login") {
    const isLoggedIn = isUserLoggedIn();

    if (!canNavigate(to)) {
      // Redirect to login if not logged in
      if (!isLoggedIn) return next({ name: "login" });

      // If logged in => not authorized
      return next({ name: "error-404" });
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
      const userData = getUserData();
      next(getHomeRouteForLoggedInUser(userData ? userData.role : null));
    }
  }
  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
